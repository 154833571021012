import { EventEmitter } from 'eventemitter3'
import type { DependencyList } from 'react'
import { useCallback, useEffect } from 'react'

import type { SubscribeEvents } from '@Types/common/subscribeEvents'
import type { IFare } from '@Types/routes/fares'
import type { ITrain } from '@Types/routes/train'
import type { FieldPath } from '@components/formElements/select/SelectDateV4/ClassicSelectDate'
import type { SearchFormValuesType } from '@components/mainPage/mainBlock/searchTrains/search/types/formTypes'
import type { StationsForMapType } from '@components/map/MapWithSearchStations/types/stationsForMapType'
import type { InfoModalData } from '@components/modals/InfoModal/InfoModal'

const emitter = new EventEmitter()

export interface EventPayloads {
  [SubscribeEvents.CLEAN_SEARCH_MODAL]: undefined
  [SubscribeEvents.CLEAR_PASSENGER_DATE]: FieldPath
  [SubscribeEvents.OPEN_ADVANCED_RESERVATION_MODAL]: { date?: string }
  [SubscribeEvents.OPEN_FARE_RULES_MODAL]:
    | [{ fare: IFare; train: ITrain }, { fare: IFare; train: ITrain }]
    | [{ fare: IFare; train: ITrain }]
  [SubscribeEvents.OPEN_MOBILE_VIDEO_PLAYER_MODAL]: { videoSlide: IVideo }
  [SubscribeEvents.OPEN_SEARCH_MODAL]: unknown
  [SubscribeEvents.OPEN_TOTAL_PRICE_DISCLAIMER_MODAL]: undefined
  [SubscribeEvents.OPEN_TRAIN_CARD_GALLERY]: undefined
  [SubscribeEvents.ORDER_PAGE_INFO_MODAL_OPEN]: InfoModalData
  [SubscribeEvents.ORDER_PAGE_MODIFY_POSTPONE]: unknown
  [SubscribeEvents.POSTPONE_MODAL_OPEN]: undefined
  [SubscribeEvents.SEARCH_HANDLER]: { formData: SearchFormValuesType }
  [SubscribeEvents.SEARCH_STATIONS]: StationsForMapType
  [SubscribeEvents.SHOW_SEARCH_FORM]: undefined
  [SubscribeEvents.SHOW_SEARCH_FORM]: undefined
}

export const useSubscribe = <E extends SubscribeEvents>(
  event: E,
  callback: (data: EventPayloads[E]) => void,
  deps?: DependencyList
) => {
  const unsubscribe = useCallback(() => {
    emitter.off(event, callback)
  }, [event, callback])

  useEffect(() => {
    emitter.on(event, callback)
    return unsubscribe
  }, [deps])

  return unsubscribe
}

export const usePublish = () => {
  return useCallback(<E extends SubscribeEvents>(event: E, data: EventPayloads[E]) => {
    emitter.emit(event, data)
  }, [])
}
